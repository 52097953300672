import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/create',
      name: 'create',
      component: () => import('@/views/committee/create.vue')
    },
    {
      path: '/history',
      name: 'history',
      component: () => import('@/views/committee/history.vue')
    },
    {
      path: '/kushof',
      name: 'kushof',
      component: () => import('@/views/kushof/index.vue')
    },
    {
      path: '/names',
      name: 'names',
      component: () => import('@/views/names.vue')
    },
    {
      path: '/kushof/:id',
      name: 'kushof-editor',
      component: () => import('@/views/kushof/editor.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/import',
      name: 'import',
      component: () => import('@/views/import.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings.vue')
    },
    {
      path: '/login/:t',
      name: 'login',
      component: () => import('@/views/autologin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login2',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-4042',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
