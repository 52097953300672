import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import swal from "sweetalert2";

// Global Varaibles

//
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;
//
window.api = 'https://app.tahdir.net'
window.api2 = 'https://tahdir.net'
// window.api = 'http://127.0.0.1:3000'
// window.api2 = 'http://127.0.0.1:8000'
window.user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {};
window.swal = swal

if (window.user?.name) {
  // $.post(api + "/committees/classnames", {
  //   jwt: window.user.jwt
  // }).then(function (a) {
  //   localStorage.setItem("classnames", a.response ? JSON.stringify(a.response) : '{}')
  // })
  if(JSON.parse(localStorage.getItem("user")).services){
  JSON.parse(localStorage.getItem("user")).services.forEach(element => {
    if(element.name == "committees"){
      if(
        new Date() > new Date(element.exdate)
      ){  
        alert("انتهى اشتراكك٫ برجاء تجديد الاشتراك من خلال التحضير")
        localStorage.removeItem("user")
        window.location = '/login'
      }
    }
  });
}
}

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
